<template>
	<div class="page">
		<div class="title">
			<span>企业基础认证</span>
			<span>切换到个人认证
				<i class="el-icon-caret-right"></i>
			</span>
		</div>
		<div class="content">
			<div class="content-title">开通店铺</div>
			<!-- 表单 -->
			<div class="form">
				<div v-if="steps == 1">
					<el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm" label-position="top">
						<el-form-item label="店铺名称">
							<el-input v-model="ruleForm.shopName" placeholder="请输入"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm">提交</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div v-if="steps == 2">
					<p>选择您所需要的认证方式</p>
					<div class="payBtn" @click="jumpPage('uac')">
						<p class="payBtn_test">银联认证</p>
						<p class="payBtn_type" v-if="chinaums == null">未认证</p>
						<p class="payBtn_type" v-if="chinaums == 0">审核中</p>
						<p class="payBtn_type" v-if="chinaums == 1">审核成功</p>
						<p class="payBtn_type" v-if="chinaums == 2">审核失败</p>
					</div>
					<div class="payBtn" @click="jumpPage('heli')">
						<p class="payBtn_test">企业钱包</p>
						<p class="payBtn_type" v-if="heli == null">未认证</p>
						<p class="payBtn_type" v-if="heli == 4">未签约</p>
						<p class="payBtn_type" v-if="heli == 5">已签约</p>
					</div>
					<el-button class="typeF4" type="primary" @click="getIdentifyInfo()" round>刷新认证状态</el-button>

				</div>
			</div>
			<div class="contentAfter">
				<div>
					<el-steps direction="vertical" :active="steps">
						<el-step title="开通店铺" description="(对应个人认证的实名认证)"></el-step>
						<el-step title="结算认证" description="(对应个人认证的基础信息完善)"></el-step>
					</el-steps>
				</div>
			</div>
		</div>

		<div class="footer">

		</div>
		<!-- 回到顶部 -->
		<el-backtop></el-backtop>
	</div>
</template>
<script>
	let BIN = require('bankcardinfo');
	import QS from 'qs'
	export default {
		data() {
			return {
				ruleForm: {
					shopName: '',
					shopType: '2',
				},
				steps: 2,
				chinaums: null,
				heli: null,
			}
		},
		computed: {

		},
		mounted() {
			this.getIdentifyInfo()
		},
		methods: {
			// 获取店铺信息
			// 银联 0: 审核中 1: 审核成功，2: 审核失败
			// 钱包 1: 进件未成功，2: 未上传商户资料，3: 未开通钱包，4: 未签约，5: 已签约
			getIdentifyInfo() {
				const loading = this.$loading({
					lock: true,
					text: '正在获取信息，请稍等',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.9)'
				});
				loading.close();
				this.$http.getContractResult().then((res) => {
					if (res.code == 0 && res.data) {
						if (res.data.userShop) {
							this.steps = 2
							this.heli = res.data.heli
							this.chinaums = res.data.chinaums
						}
					}
				}).finally(d => {
					setTimeout(() => {
						loading.close();
					}, 100);
				});
			},
			// 跳转页面 签约结果预计在十分钟内生效，点击下方按钮返回我的店铺刷新签约状态
			jumpPage(v) {
				switch (v) {
					case 'heli':
						if (this.heli == 4) {
							// 查詢狀態然後直接去簽約
							this.signContract()
						} else if (this.heli == 5) {
							// 5不跳轉
						} else {
							this.$router.replace("/agencyRegister/helipayAuthentication");
						}
						break;
					case 'uac':
						if (this.chinaums == null || this.chinaums == 2) {
							this.$router.replace("/agencyRegister/uacAuthentication");
						}
						break;
					default:
						break;
				}
			},
			// 查詢跳轉狀態
			signContract() {
				this.$http.signContract().then(r => {
					if (r.code == 0) {
						this.qianyueModule()
						let url = `${r.data.url}?${QS.stringify(r.data)}`
						console.log(url);
						window.open(url)
					} else {
						this.$message({
							message: r.msg,
							type: "success",
						});
					}
				})
			},
			// 弹窗
			qianyueModule() {
				this.$alert('请在弹出的新窗口完成签约，签约结果预计在十分钟内生效，点击下方按钮返回支付认证刷新签约状态', '签约提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.replace("/agencyRegister/createShop");
					}
				});
			},
			// personForm.legalCardDeadline = '9999-12-31'
			submitForm() {
				this.$http.createUserShop(this.ruleForm).then(res => {
					if (res.code == 0) {
						this.$message.success(res.msg);
						this.steps += 1
					} else {
						this.$message.error(res.msg);
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.payBtn {
		width: 80%;
		margin: 20px 10%;
		height: 100px;
		border-radius: 20px;
		border: #303133 solid 1px;
		position: relative;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
	}

	.payBtn_test {
		position: absolute;
		top: 50%;
		left: 10%;
		transform: translate(0%, -50%);
	}

	.payBtn_type {
		position: absolute;
		top: 50%;
		right: 10%;
		transform: translate(0%, -50%);
	}

	.typeF4 {
		position: absolute;
		right: 50%;
		transform: translate(50%, 0%);
	}

	div.page {
		width: 100vw;
		min-height: 100vh;
		background-color: #fff;
		padding: 20px 0;

		div.title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 40vw;
			min-width: 500px;
			margin: auto;
			padding: 20px 0;

			&>span:first-of-type {
				font-size: 18px;
				color: #303133;
			}

			span {
				font-size: 16px;
				color: #606266;
			}
		}

		div.content {
			width: 40vw;
			min-width: 500px;
			min-height: 100vh;
			margin: auto;
			// padding: 20px;
			background: #ffffff;
			border: 1px solid #dfe2e7;
			position: relative;

			div.content-title {
				padding: 20px;
				font-size: 16px;
				text-align: center;
				background-color: #f5f6f8;
				border-bottom: 1px solid #dfe2e7;
			}

			div.form {
				padding: 20px;
				background: #ffffff;
			}

			form.el-form {
				padding: 20px;
				background: #f3f6f9;
			}

			div.contentAfter {
				background-color: #ffffff;
				border: 1px solid #dfe2e7;
				width: 250px;
				position: absolute;
				top: 0;
				right: -310px;
				padding: 20px;
			}
		}

		.el-form-item__content {
			display: flex;

			&>div {
				flex: 1;
			}
		}

		.el-step__main {
			margin-bottom: 10px;
		}

		.avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader .el-upload:hover {
			border-color: #409eff;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 178px;
			height: 178px;
			line-height: 178px;
			text-align: center;
		}

		.avatar {
			width: 178px;
			height: 178px;
			display: block;
		}
	}
</style>
